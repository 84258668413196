.footer-container {
	display: flex;
	position: fixed;
	justify-content: space-around;
	width: 100%;
	visibility: hidden;
	border: 1px solid #ededed;
	background: rgba(255, 255, 255, 0.8);
	opacity: 0;
	&.show {
		visibility: visible;
		transform: translateY(1%);
		opacity: 1;
		position: fixed;
		bottom: 0;
	}
	&.show-block {
		visibility: visible;
		transform: translateY(1%);
		opacity: 1;
		position: static;
	}
	.footer {
		box-sizing: border-box;
		bottom: 0px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		backdrop-filter: blur(5px);
		background: rgba(255, 255, 255, 0.8);
		border-radius: 5px;
		padding: 0 20px;
		transition: transform 0.2s, opacity 0.2s;
		max-width: 1156px;
		align-items: center;
		overflow-x: auto;
		gap: 12px;

		:global {
			.ant-btn {
				padding: 16px 0;
			}
		}
		button {
			color: var(--secondary-txt-color);
			&:hover {
				color: var(--main-color) !important;
			}
		}
		a {
			padding: 16px 0;
			color: var(--secondary-txt-color);
			transition: color linear 0.1s;
			white-space: nowrap;
			font-size: 12px;
			line-height: 48px;
			&:hover {
				color: var(--main-color);
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.footer {
		.ant-btn {
			padding: 0 !important;
		}

		a {
			padding: 0 !important;
		}
	}
}
