/* 目录菜单组件 */
.menu-container {
	position: fixed;
	left: -300px;
	top: 0;
	width: 300px;
	height: 100vh;
	background: #fff;
	z-index: 500;
	display: flex;
	flex-direction: column;
	transform: translateX(0);
	visibility: hidden;
	transition: all 0.2s linear;
}
.menu-container.show {
	transform: translateX(100%);
	visibility: visible;
}

.menu-title {
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.icon-btn {
	cursor: pointer;
}

.icon-close {
	width: 20px;
	height: 20px;
}

.directory-container {
	overflow: auto;
	z-index: 999;
}

.btn-back {
	display: none;
	box-sizing: border-box;
	padding: 12px;
	cursor: pointer;
	font-size: 14px;

	&.show {
		display: block;
	}
}

.catagory-list {
	display: flex;
	width: 100%;
	flex-direction: column;

	> li {
		box-sizing: border-box;
		padding: 6px;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		transition: all 0.1s linear;
	}

	.btn-list {
		display: flex;
		flex-direction: row;
		flex-shrink: 0;
	}
}

/* 二级导航组件 */
.secondery-nav {
	height: 48px;
	max-width: 1156px;
	display: flex;
	flex-direction: row;
	/* overflow-x: scroll; */
	/* gap: 30px; */
	&.fixed {
		position: fixed;
		overflow: hidden;
		top: 0;
		width: 100%;
		z-index: 9999;
		background-color: white;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}
}
/* 二级导航  */
.secondery-nav-tab {
	display: flex;
	flex-direction: row;
	gap: 20px;

	> a {
		flex-shrink: 0;
		line-height: 48px;
		padding: 0 15px;
		color: var(--secondary-txt-color);
		cursor: pointer;
		transition: opacity 0.1s linear;
	}
	> a:hover {
		opacity: 0.8;
	}
	> a.selected {
		color: var(--main-color);
	}
	> a.selected:hover {
		opacity: 1;
	}
}

@media only screen and (max-width: 600px) {
	.secondery-nav-tab {
		width: 100%;
		overflow-x: scroll;
	}
}

.cata-btn {
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.cata-btn:hover {
	opacity: 0.6;
}

.cata-list-btn {
	width: 32px;
	height: 32px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: var(--main-color);
	}
}

.cata-title {
	flex-grow: 1;
	line-height: 36px;
	padding: 0 8px;
	font-size: 16px;
	border-radius: 10px;
	color: var(--primary-txt-color);
	cursor: pointer;

	&:hover {
		background: var(--main-color);
		color: #fff;
	}

	&.selected {
		background: var(--main-color);
		color: #fff;
	}
}

/* carousel */
.carousel-container {
	width: 100%;
	aspect-ratio: 1024 / 375;
	border-radius: 10px;
}

@media only screen and (max-width: 600px) {
	.carousel-inner {
		width: 100%;
	}
}

.carousel-image {
	display: block;
	width: 100%;
	aspect-ratio: 1024 / 375;
	position: relative;
	cursor: pointer;
}

.module-title {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 24px;
	font-weight: 600;
	margin-top: 12px;
	gap: 10px;
}

.module-title i {
	display: inline-block;
	width: 20px;
	height: 20px;
	color: var(--main-color);
}

.promoted-goods-sec {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.promoted-container {
	width: 100%;
	max-width: 1156px;
	position: relative;
}

.scrollable-area {
	display: flex;
	flex-direction: row;
	gap: 20px;
	overflow: hidden;
}
.promoted-pager {
	z-index: 100;
	position: absolute;
	height: 100%;
	opacity: 0.5;
	padding: 0 10px;
}
.promoted-pager:disabled:hover {
	opacity: 0.5;
}
.promoted-pager i {
	display: block;
	width: 20px;
	height: 20px;
}

.promoted-pager:hover {
	opacity: 0.8;
}
.prev-button {
	left: 0;
	top: 0;
}
.next-button {
	right: 0;
	top: 0;
}

.promoted-panel {
	display: flex;
	gap: 20px;
	padding: 20px 0;

	> * {
		width: 176px;
	}
}

@media only screen and (max-width: 600px) {
	.promoted-pager {
		display: none;
	}
	.mobile {
		display: flex;
	}
	.desktop {
		display: none;
	}

	.promoted-panel {
		padding: 0 12px;
		scroll-snap-type: x mandatory;
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
		overflow-y: hidden;
		scrollbar-width: thin;
		scrollbar-color: #888 #f1f1f1;

		> * {
			flex: 0 0 100%;
			scroll-snap-align: center;
		}
	}
}

.products-list {
	position: relative;
	display: grid;
	gap: 20px;
	padding: 20px 0;
	grid-template-columns: repeat(6, 1fr);
	box-sizing: border-box;

	> * {
		width: 100%;
		overflow: hidden;
	}
}

@media only screen and (max-width: 600px) {
	.products-list {
		flex-direction: column;
		width: 100vw;
		gap: 12px;
		box-sizing: border-box;
		padding: 20px 12px;
		margin: 0 auto;
		grid-template-columns: repeat(1, 1fr);
	}
}

.loading-indicator {
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: row;
	gap: 12px;
	width: 100%;
	height: 100px;
	transition: opacity linear 0.2s;

	.loading-circle {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: #ccc;
		animation: loadingAnimation 1s linear infinite;
	}
	.loading-circle:nth-child(1) {
		animation-delay: 0.3s;
	}
	.loading-circle:nth-child(2) {
		animation-delay: 0.6s;
	}
}

@media only screen and (max-width: 600px) {
	.loading-indicator {
		height: 100px;
	}
}

.icon-btn {
	width: 20px;
	height: 20px;
}

@media only screen and (max-width: 600px) {
	.icon-btn {
		width: 32px;
		height: 32px;
		background-repeat: no-repeat;
		background-position: center;
	}
}

.layout-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 20px;
}

@media only screen and (max-width: 600px) {
	.layout-container {
		gap: 0;
	}
}

.main-container {
	position: relative;
	width: 1156px;
	display: flex;
	flex-direction: column;
	/* padding: 12px 0; */
}

@media only screen and (max-width: 600px) {
	.main-container {
		width: 100vw;
	}
}
